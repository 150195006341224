<template>
  <div class="user-data">
    <div class="h6">个人信息</div>
    <div class="in-boxs">
      <div class="img-box">
        <img class="data-img" :src="avatar || require('../../../../../assets/images/grzx_iocn_tx.png')" alt="" />
        <el-upload
          :action="action"
          v-loading="loading"
          :data="upData"
          :name="name"
          :before-upload="begin"
          :limit="1"
          :on-success="upTx"
          :show-file-list="false"
        >
          <div class="edit-box">
            <img src="../../../../../assets/images/grzx_iocn_gh.png" alt="" />
            <span>更换</span>
          </div>
        </el-upload>
      </div>
      <div class="inp-box cf">
        <div class="inp-item fl">
          <div class="label">用户名</div>
          <input type="text" v-model="username" placeholder="请输入用户名" />
        </div>
        <div class="inp-item fl">
          <div class="label">手机号</div>
          <input type="text" v-model="mobile" placeholder="请输入手机号" />
        </div>
        <div class="inp-item fl">
          <div class="label">地址</div>
          <input type="text" v-model="addr" placeholder="请输入地址" />
        </div>
      </div>
      <button class="bc-btn" @click="bc">保存</button>
    </div>
  </div>
</template>
<script>
import mixins from "../mixins";
export default {
  mixins,
};
</script>
<style scoped>
@import "../style.css";
</style>