export default {
    data: function () {
        return {
            mobile: '',
            username: '',
            addr: '',
            avatar: '',
            id: '',
            action: this.url + '/api/base/personalUpload.html',
            name: "",
            upData: {
                token: localStorage['yhtoken'],
            },
            loading:false,
        }
    },
    created() {
        this.setData();
    },
    methods: {
        begin:function(){
            console.log(123)
            this.loading = true;
        },
        upTx: function (res) {
            this.loading = false
            this.avatar = res.data.image_url || '';
        },
        setData: function () {
            console.log(this.$store.state.userInfo)
            this.mobile = this.$store.state.userInfo.mobile
            if(this.$store.state.userInfo.user_type == 1){
              
            }else{
                this.username = this.$store.state.userInfo.name ||this.$store.state.userInfo.enterprise_name 
            }
            // this.username = this.$store.state.userInfo.name 
            this.addr = this.$store.state.userInfo.addr
            this.avatar = this.$store.state.userInfo.avatar || ''
        },
        bc: function () {
            this.$post('/api/user/addUserInfo.html', {
                mobile: this.mobile,
                username: this.username,
                addr: this.addr,
                avatar: this.avatar,
                token:true
            }).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    localStorage.setItem('user',JSON.stringify(res.data))
                    localStorage.setItem('token',res.data.token)
                    localStorage.setItem('isLogin',1);
                    this.$store.commit('changeLogin',1)
                    return this.$message('保存成功');
                } else {
                    return this.$message(res.msg);
                }

            }).finally(() => {

            })
        }
    },
}